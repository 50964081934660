import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      <div className="container-fluid bg-dark text-light mt-5 py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6">
              <h4 className="d-inline-block   text-danger text-uppercase border-bottom border-5 border-secondary mb-4">
                OmegaSoft
              </h4>
              <p className="text_style">
                OmegaSoft and Technologies specializes in software and website development, as well as
                being a full service technology solutions provider that is
                skilled in all phases of ERP systems, Accounting management,
                School management, Hospital management, Service Center management, POS system and other
                services on demand.
              </p>
            </div>

            <div className="col-lg-3 col-md-6">
              <h4 className="d-inline-block   text-danger text-uppercase border-bottom border-5 border-secondary mb-4">
                Our Products
              </h4>
              <div className="text-center d-flex flex-column justify-content-center">
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="/omegaERP"
                >
                  <i className="fa fa-angle-right me-2"></i>OmegaERP
                </Link>
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="/omegaDMS"
                >
                  <i className="fa fa-angle-right me-2"></i>OmegaDMS
                </Link>
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="/omegaHMS"
                >
                  <i className="fa fa-angle-right me-2"></i>OmegaHMS
                </Link>
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="/DoctorPad"
                >
                  <i className="fa fa-angle-right me-2"></i>DoctorsPad
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <h4 className="d-inline-block   text-danger text-uppercase border-bottom border-5 border-secondary mb-4">
                Contact Us
              </h4>
              <div className="mx-5 text-start d-flex flex-column  ">
                <span className=" text-danger mb-3">Mymensingh Office</span>
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="#"
                >
                  <i className="fa fa-map-marker   text-danger me-2 "></i>
                  Holding-46, Word-06, S.A Sharkar Road, Mymensingh
                </Link>

                <br/>
                <span className=" text-danger mb-3">Dhaka Office</span>
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="#"
                >
                  <i className="fa fa-map-marker   text-danger me-2"></i>
                  67, Sher-e-Bangla Nagar, Agargaon, Dhaka
                </Link>

                <br/>
                <span className=" text-danger mb-3">USA Office</span>
                <Link
                  className="text-decoration-none text-light text-light mb-2"
                  to="#"
                >
                  <i className="fa fa-map-marker   text-danger me-2"></i>
                  1300 Bay Area Blvd B150-34 
                  Houston, TX 77058
                </Link>
                <Link
                  className="text-decoration-none text-light text-light mb-2 mt-2"
                  to="#"
                >
                  <i className="fa fa-envelope   text-danger me-2"></i>{" "}
                  info@omegasoftbd.com
                </Link>  
              </div>

              <div className="d-flex  px-5 pt-2">
                <a
                  className="btn btn-lg btn-danger btn-lg-square rounded-circle me-2"
                  href="https://www.facebook.com/omegasoftbd/"
                >
                     <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-lg btn-danger btn-lg-square rounded-circle me-2"
                  href="https://www.youtube.com/channel/UCqD5yLVhsJdT3aZhFGlCIUg"
                >
               
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn btn-lg btn-danger btn-lg-square rounded-circle"
                  href="#"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-light border-top border-secondary py-4">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-8 text-center text-md-start">
              <p className="mb-md-0 list-inline">
                Copyright {new Date().getFullYear()} All rights reserved by{" "}
                <a
                  className="text-decoration-none text-light "
                  href="https://omegasoftbd.com"
                >
                  OmegaSoft
                </a>
              </p>
              <div className="d-flex mt-3">
                <a
                  className="btn btn-lg btn-danger btn-lg-square rounded-circle me-2"
                  href="https://www.facebook.com/omegasoftbd/"
                >
               
                 <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-lg btn-danger btn-lg-square rounded-circle me-2"
                  href="https://www.youtube.com/channel/UCqD5yLVhsJdT3aZhFGlCIUg"
                >
                   <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn btn-lg btn-danger btn-lg-square rounded-circle"
                  href="#"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              
              </div>
            </div>
            <div className="col-md-4 text-center text-md-end  text-light">
              <p className="mb-0">
                Designed by{" "}
                <a className="text-decoration-none  text-light" href="https://omegasoftbd.com">
                  OmegaSoft
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <a href="#" class="btn btn-danger back-to-top">
        <i class="fa fa-angle-double-up"></i>
      </a>
    </div>
  );
};

export default Footer;
