import React from "react";
import "./About.css";
import about from "../../../assets/images/omegasoft_work1.jpg";
import about1 from "../../../assets/images/omegasoftwork.jpg";

const About = () => {
  return (
    <div className="">
      <div className="container-fluid pt-5">
        <div className="container-about row ">
          <div className="col-lg-6 ">
            <div className="">
              <h2 className="d-inline-block text-danger ">About Us</h2>
              <p className="display-6 ">Company Profile</p>
            </div>
            <p className="text_style ">
            OmegaSoft and Technologies, a leading software company in Dhaka, offers a comprehensive range of IT solutions, specializing in software and website development. Their expertise encompasses all phases of ERP systems, Accounting management, School management, Hospital management, POS systems, and more. OmegaSoft serves clients of all sizes, from startups to large enterprises, providing customized solutions that streamline operations and optimize efficiency. Their team of highly skilled professionals is dedicated to delivering superior service, ensuring that businesses leverage the latest technologies to stay competitive in an ever-evolving digital landscape.
            </p>
          </div>

          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className="position-relative h-100">
              <div className="row g-4 pt-3">
                <div className="col-sm-12 col-12 ">
                  <div className="bg-light text-center rounded-5 py-3">
                    <i className="fa fa-3x fa-thin fa-binoculars text-danger mb-2"></i>
                    <h6 className="mb-0 d-block text-danger">Our Vision</h6>
                  </div>
                  <p className="text_style pt-3">
                    OmegaSoft vision is to earn global appreciation and respect
                    as the most trusted name in the empire of software & website
                    development and create an excellent network among our
                    partners, stakeholders, clients and employees.
                  </p>
                </div>

                <div className="col-sm-12 col-12 ">
                  <div className="bg-light text-center rounded-5 py-4">
                    <i className="fa fa-3x fa-thin fa-handshake text-danger mb-2"></i>
                    <h6 className="mb-0 d-block text-danger">Our Mission</h6>
                  </div>

                  <p className="text_style pt-3">
                    OmegaSoft is deeply committed to enhancing the business
                    value of clients & partners on a long term and sustainable
                    basis through technical excellence, fair business practices,
                    people brilliance and professional integrity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-about how-section1">
        <div className="row">
          <div className="col-md-6 how-img">
            <img className=" img-fluid" src={about} alt="" />
          </div>
          <div className="col-md-6 text_style">
            <h2 className="display-6">Honesty</h2>
            <p className="subheading">
              Honesty and Integrity towards our work and our clients. Steadfast
              adherence to what we say and what we do.
            </p>
            <p className="text-muted">
              Honesty and Integrity towards our work and our clients. Steadfast
              adherence to what we say and what we do.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  text_style">
            <h2 className="display-6">Relationship</h2>
            <p className="subheading">
              We believe in long-term relations with our clients. We want them
              to become part of our Team In OmegaSoft family.
            </p>
            <p className="text-muted">
              We believe in long-term relations with our clients. We want them
              to become part of our Team In OmegaSoft family.
            </p>
          </div>
          <div className="col-md-6 how-img">
            <img className=" img-fluid" src={about1} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 how-img">
            <img className=" img-fluid" src={about} alt="" />
          </div>
          <div className="col-md-6  text_style">
            <h2 className="display-6">Discipline</h2>
            <p className="subheading">
              Discipline - It is one of the important factors helping us achieve
              our desired goals.
            </p>
            <p className="text-muted">
              Discipline - It is one of the important factors helping us achieve
              our desired goals.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text_style">
            <h2 className="display-6">Commitment</h2>
            <p className="subheading">
              Commitment / Focus towards work- It always helps us in improving
              our services to our clients and our team.
            </p>
            <p className="text-muted">
              Commitment / Focus towards work- It always helps us in improving
              our services to our clients and our team.
            </p>
          </div>
          <div className="col-md-6 how-img">
            <img className=" img-fluid" src={about1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
