import React from "react";
import "./TopNav.css";

const TopNav = () => {
  return (
    <div className="header container-fluid py-1  border-bottom d-none d-lg-block  bg-dark text-light ">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center"> 
                {/* <i className="bi bi-telephone me-2"></i> */}
                {/* <span className="top-nav-text">+88 01786-572929</span>  */}
              {/* <span className="text-light"> &nbsp;&nbsp;&nbsp; </span>  */}
                <i className="bi bi-envelope me-2 "></i>{" "}
                <span className="top-nav-text">info@omegasoftbd.com</span> 
            </div>
          </div>
          <div className="col-md-6 text-center text-lg-end">
            <div className="d-inline-flex align-items-center">
              <a
                className="text-light px-2"
                href="https://www.facebook.com/omegasoftbd/"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="text-light px-2"
                href="https://www.youtube.com/channel/UCqD5yLVhsJdT3aZhFGlCIUg"
              >
                <i className="fab fa-youtube"></i>
              </a>

              <a className="text-light ps-2" href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
